import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import { API_URL } from '../actions/constant';
import { geterrors, clearerror } from './errorReducer'



export const counterSlice = createSlice({
    name: 'crud',
    initialState: {
        list: null,
        loading: false,
        add: null,
        edit: null,
        deleteone: null,
        viewone: null,
        reduxname: null
    },
    reducers: {
        listloading: (state, action) => {
            state.loading = action.payload
            state.list = null
            state.add = {}
            state.edit = {}
            state.deleteone = {}
            state.viewone = null
            state.reduxname = null
        },
        loading: (state, action) => {
            state.loading = true
        },
        stoploading: (state, action) => {
            state.loading = false
        },
        list: (state, action) => {
            state.list = action.payload.data
            state.loading = false
            state.reduxname = action.payload.reduxName

        },
        listrecent: (state, action) => {
            state.listrecent = action.payload.data
            state.loading = false
            state.reduxname = action.payload.reduxName

        },
        add: (state, action) => {
            state.add = action.payload
            state.loading = false

        },
        edit: (state, action) => {
            state.edit = action.payload
            state.loading = false

        },
        deleteone: (state, action) => {
            state.deleteone = action.payload
            state.loading = false

        },
        viewone: (state, action) => {
            state.viewone = action.payload
            state.loading = false

        },
    },
})

export const { list, add, edit, deleteone, viewone, loading, listloading, stoploading, listrecent } = counterSlice.actions

//API CALLS
export const listData = (endpoint, params, reduxName) => dispatch => {
    dispatch(listloading(true));
    dispatch(clearerror())
    axios.get(`${API_URL}${endpoint}${params ? params : "?"}`)
        .then(res => {
            // dispatch(list(res.data));
            dispatch(list({ data: res.data, reduxName }));

        })
        .catch(err => {
            console.log("err", err)
            dispatch(stoploading())
            dispatch(geterrors(err.response.data))
        });
};

export const addData = (endpoint, data) => dispatch => {
    dispatch(loading(true));
    dispatch(clearerror())

    axios.post(API_URL + endpoint, data)
        .then(res => {
            dispatch(add(res.data));
        })
        .catch(err => {
            console.log("err", err)
            dispatch(stoploading())
            dispatch(geterrors(err.response.data))
        });
};
export const editData = (endpoint, data) => dispatch => {
    dispatch(loading(true));
    dispatch(clearerror())

    axios.post(API_URL + endpoint, data)
        .then(res => {
            dispatch(edit(res.data));
        })
        .catch(err => {
            console.log("err", err)
            dispatch(stoploading())
            dispatch(geterrors(err.response.data))
        });
};

export const deleteData = (endpoint, data) => dispatch => {
    dispatch(loading(true));
    dispatch(clearerror())

    axios.post(API_URL + endpoint, data)
        .then(res => {
            dispatch(deleteone(res.data));
        })
        .catch(err => {
            console.log("err", err)
            dispatch(stoploading())
            dispatch(geterrors(err.response.data))
        });
};

export const viewoneData = (endpoint, data) => dispatch => {
    dispatch(loading(true));
    dispatch(clearerror())



    axios.post(API_URL + endpoint, data)
        .then(res => {
            dispatch(viewone(res.data));
        })
        .catch(err => {
            console.log("err", err)
            dispatch(stoploading())
            dispatch(geterrors(err.response.data))
        });
};

export const listRecent = (endpoint, params, reduxName) => dispatch => {
    dispatch(listloading(true));
    dispatch(clearerror())
    axios.get(`${API_URL}${endpoint}${params ? params : "?"}`)
        .then(res => {
            // dispatch(list(res.data));
            dispatch(listrecent({ data: res.data, reduxName }));

        })
        .catch(err => {
            console.log("err", err)
            dispatch(stoploading())
            dispatch(geterrors(err.response.data))
        });
};

export default counterSlice.reducer
